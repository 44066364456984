import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { environment } from 'environments/environment';

import { LanguageService } from '@core/services/language/language.service';
import { Article as ArticleModel } from '@core/types/articles/article.model';

@Component({
  selector: 'apd-article-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss'],
})
export class ArticleCategoryComponent implements OnInit {
  @Input() tabIndex: number;
  @Input() display: boolean;
  @Input() category: string;
  @Input() categoriesList: Array<string>;
  @Input() articles: Array<ArticleModel>;
  @Output() setPageEventHandler = new EventEmitter<{
    page: number;
    index: number;
  }>();
  @Output() searchEventHandler = new EventEmitter<{ keyword: string }>();
  @Output() triggerSearchByTag = new EventEmitter<string>();

  cdnBasePath = environment.cdnBasePath;

  currentLang: string;

  constructor(
    private router: Router,
    private languageService: LanguageService
  ) {}

  ngOnInit(): void {
    this.currentLang = this.languageService.getCurrentLang();

    /**
     * Redirect to new page and show top contents
     */
    this.router.events.subscribe(event => {
      if (!(event instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  onPageChange($event: number): void {
    this.setPageEventHandler.emit({ page: $event, index: this.tabIndex });
  }

  onSearch(event: { keyword: string }): void {
    this.searchEventHandler.emit(event);
  }

  searchByTag(tag: string): void {
    this.triggerSearchByTag.emit(tag);
  }
}
