<div class="filters-bar bg--three">
  <div class="container">
    <div class="filters-bar-btn-grp">
      <div class="dropdown-container">
        <button
          type="type"
          id="filter-bar-property"
          class="btn filters-bar-btn"
          [ngClass]="getClassIfFilterExists('propertyType')"
          (click)="toggleSearchFilterPopup($event)">
          {{ getButtonTitle('propertyTypes') }}
        </button>
        <div class="dropdown-filter-content"></div>
      </div>
      <div class="dropdown-container">
        <button
          type="type"
          id="filter-bar-bedrooms"
          class="btn filters-bar-btn"
          [ngClass]="getClassIfFilterExists('bedrooms')"
          (click)="toggleSearchFilterPopup($event)">
          {{ getButtonTitle('bedrooms') }}
        </button>
        <div class="dropdown-filter-content"></div>
      </div>
      <div class="dropdown-container">
        <button
          type="type"
          id="filter-bar-bathrooms"
          class="btn filters-bar-btn"
          [ngClass]="getClassIfFilterExists('bathrooms')"
          (click)="toggleSearchFilterPopup($event)">
          {{ getButtonTitle('bathrooms') }}
        </button>
        <div class="dropdown-filter-content"></div>
      </div>
      <div class="dropdown-container">
        <button
          type="type"
          id="filter-bar-carspaces"
          class="btn filters-bar-btn"
          [ngClass]="getClassIfFilterExists('carSpaces')"
          (click)="toggleSearchFilterPopup($event)">
          {{ getButtonTitle('carSpaces') }}
        </button>
        <div class="dropdown-filter-content"></div>
      </div>
      <div class="dropdown-container">
        <button
          type="type"
          id="filter-bar-price"
          class="btn filters-bar-btn"
          [ngClass]="getClassIfFilterExists('price')"
          (click)="toggleSearchFilterPopup($event)">
          {{ getButtonTitle('priceRange') }}
        </button>
        <div class="dropdown-filter-content"></div>
      </div>

      <div class="show-map-container">
        <apd-map-toggle></apd-map-toggle>
      </div>
    </div>
  </div>
</div>
