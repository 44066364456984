import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'daySuffix',
})
export class DaySuffixPipe implements PipeTransform {
  transform(day: string): string {
    const iDay = parseInt(day);
    const dayCondition = iDay % 10;

    if (iDay > 10 && iDay < 14) {
      return iDay + 'th';
    } else if (dayCondition === 1) {
      return iDay + 'st';
    } else if (dayCondition === 2) {
      return iDay + 'nd';
    } else if (dayCondition === 3) {
      return iDay + 'rd';
    } else {
      return iDay + 'th';
    }
  }
}
