export const locale = {
  lang: 'en',
  data: {
    // START Global Translations
    _BUYER_INCENTIVES: 'Buyer Incentives',
    _BUYING_LIVING: 'Buying & Living',
    _BUYERS_GUIDE: 'Buyers Guide',
    _HOUSE_LAND_PACKAGES: 'House & Land Packages',
    _INDUSTRY_PROFILES: 'Industry Profiles',
    _SUBURB_PROFILES: 'Suburb Profiles',
    _DEVELOPMENT: {
      APARTMENT: {
        SINGULAR: 'Apartment',
        PLURAL: 'Apartments',
      },
      TOWNHOUSE: {
        SINGULAR: 'Townhouse',
        PLURAL: 'Townhouses',
      },
      LAND_ESTATE: {
        SINGULAR: 'Land Estate',
        PLURAL: 'Land Estates',
      },
      PENTHOUSE: {
        SINGULAR: 'Penthouse',
        PLURAL: 'Penthouses',
      },
      PRESTIGE_HOME: {
        SINGULAR: 'Prestige Home',
        PLURAL: 'Prestige Homes',
      },
      VILLA: {
        SINGULAR: 'Villa',
        PLURAL: 'Villas',
      },
      TERRACE: {
        SINGULAR: 'Terrace',
        PLURAL: 'Terraces',
      },
    },
    _PROPERTY: {
      TYPE: 'Property Type',
      BEDROOM: {
        SINGULAR: 'Bedroom',
        PLURAL: 'Bedrooms',
      },
      BATHROOMS: 'Bathrooms',
      CARS: 'Cars',
      CAR_SPACES: 'Car Spaces',
      PRICE: 'Price',
      DEVELOPER: 'Developer',
    },
    _SUPPLIER: {
      AGENT: {
        SINGULAR: 'Agent',
        PLURAL: 'Agents',
      },
      ARCHITECT: {
        SINGULAR: 'Architect',
        PLURAL: 'Architects',
      },
      BUILDER: {
        SINGULAR: 'Builder',
        PLURAL: 'Builders',
      },
      DEVELOPER: {
        SINGULAR: 'Developer',
        PLURAL: 'Developers',
      },
      PROJECT_MARKETER: {
        SINGULAR: 'Project Marketer',
        PLURAL: 'Project Marketers',
      },
      INTERIOR_DESIGNER: {
        SINGULAR: 'Interior Designer',
        PLURAL: 'Interior Designers',
      },
      LANDSCAPE_ARCHITECT: {
        SINGULAR: 'Landscape Architect',
        PLURAL: 'Landscape Architects',
      },
      BUILDER_DEVELOPER: {
        SINGULAR: 'Builder/Developer',
        PLURAL: 'Builders/Developers',
      },
      PROJECT_MANAGER: {
        SINGULAR: 'Development Partner',
        PLURAL: 'Development Partners',
      },
    },
    _DEMOGRAPHICS: {
      HOUSEHOLDS: 'Households',
      YOUNGER_PROFESSIONALS: 'Younger Professionals',
      FIRST_HOME_BUYERS: 'First Home Buyers',
      EARLY_CAREERS: 'Early Access',
    },
    _PRICES: {
      MIN: 'Min',
      MAX: 'Max',
      AVERAGE_HOUSE: 'Average House Prices',
    },
    _FORM: {
      PLACEHOLDER: {
        SEARCH: 'Search by suburb or development',
        NAME: 'Name',
        PHONE_NUMBER: 'Phone number',
        EMAIL_ADDRESS: 'Email Address',
        ANY: 'Any',
      },
      FILTERS: 'Filters',
      CLEAR_FILTERS: 'Clear filters',
      SHOW_FILTER_RESULTS: 'Show {{ total }} results',
      CLEAR: 'Clear',
      APPLY_FILTERS: 'Apply Filters',
      APPLY: 'Apply',
    },
    // END Global Translations

    // START Home Page Translations
    HOME: {
      TITLES: {
        BANNER_TITLE: {
          MAIN: {
            DEFAULT: 'Home of new property developments',
            INVEST: 'Find your ideal investment property',
            STATES: 'New developments & investment properties in {{state}}',
          },
        },
        PROPERTY_OPPORTUNITIES: {
          MAIN: {
            DEFAULT:
              "Where buyers find the newest apartments and townhouses for sale, from Australia's top developers.",
            STATES:
              "Where buyers find the newest apartments and townhouses for sale, from {{state}}'s top suburbs.",
          },
        },
        FEATURED_INVESTMENT: {
          MAIN: {
            DEFAULT:
              'Featured investment apartments & townhouses from across the nation',
            STATES:
              'Featured investment apartments & townhouses across {{state}}',
            ACT: "Featured investment apartments & townhouses across our nation's capital",
          },
        },
        BUYING_INCENTIVES: {
          MAIN: {
            DEFAULT: 'Property buying offers & incentives for your next home',
            INVEST:
              'Property buying offers & incentives for your next investment property',
            STATES: 'Property buying offers & incentives across {{state}}',
            ACT: "Property buying offers & incentives across our nation's capital",
          },
          SUB: {
            DEFAULT:
              'Property investment grants, discounts and clearance deals in highly prized suburbs in Sydney, Melbourne, Brisbane and more',
            SUBURB:
              'Property investment grants, discounts and clearance deals in highly prized suburbs in and around {{suburb}}',
          },
        },
        SUBSCRIPTION: {
          MAIN: {
            DEFAULT:
              'Looking for more? Get the latest offers straight to your inbox',
          },
        },
        PROPERTY_DEALS: {
          MAIN: {
            DEFAULT: "Australia's best investment property deals",
            STATES: "{{state}}'s best investment property deals",
          },
        },
        INVESTMENT_SUBURBS: {
          MAIN: {
            DEFAULT: 'Top investment suburbs for off the plan buyers',
            STATES:
              "{{state}}'s top investment suburbs for off the plan buyers",
          },
        },
        BUYERS_GUIDE: {
          MAIN: {
            DEFAULT: 'Your off the plan buying guide',
          },
          SUB: {
            DEFAULT:
              'Get the ins and outs of property investment in Australia from our handy real estate guide',
            STATES:
              'Get the ins and outs of property investment in {{state}} from our handy real estate guide',
          },
        },
        BUYING_LIVING: {
          MAIN: {
            DEFAULT: 'Buying and living investment properties in Australia',
            STATES: 'Buying and living investment properties in {{state}}',
          },
          SUB: {
            DEFAULT:
              'Get the ins and outs of property investment in Australia from our handy real estate guide',
            STATES:
              'Get the ins and outs of property investment in {{state}} from our handy real estate guide',
          },
        },
      },
      HEADER: {
        FIND_INVESTMENT_PROPERTY: {
          DEFAULT: 'Home of new property developments',
          INVEST: 'Find your ideal investment property',
          STATE: 'New developments & investment properties in {{state}}',
        },
        INVESTMENT_PROPERTY_OPPORTUNITIES:
          "Where buyers find the newest apartments and townhouses for sale, from {{state}}'s top suburbs.",
        FEATURED_INVESTMENT: 'Featured investment apartment & townhouses',
        AUSTRALIA_BEST_INVESTMENT: "{{state}}'s best investment property deals",
        PROPERTY_BUYING_OFFERS: 'Property buying offers & incentives',
        GET_LATEST_OFFERS: 'Get the latest offers straight to your inbox',
        TOP_INVESTMENT_SUBURBS:
          'Top investment suburbs for off the plan buyers',
        OFF_THE_PLAN_BUYING_GUIDE: 'Your off the plan buying guide',
        BUYING_LIVING_INVESTMENT_PROPERTIES:
          'Buying and living investment properties in {{state}}',
      },
      PLACEHOLDER: {
        SEARCH: 'Search by suburb or development',
      },
      BUTTON_TEXT: {
        MORE_FEATURED_PROPERTIES: 'See more featured properties',
        MORE_BEDROOM_PROPERTIES: 'See more {{ bedroom }} bedroom properties',
        SEE_ALL_TOP_SUBURBS: 'See all top suburbs',
        VIEW_BUYERS_GUIDE: 'View Buyers Guide',
        SEE_ALL_ARTICLES: 'See all articles',
      },
      TEXT: {
        PROPERTY_BUYING_DESCRIPTION:
          'Property investment grants, discounts and clearance deals in highly prized suburbs in Sydney, Melbourne, Brisbane and more!',
        OFF_THE_PLAN_BUYING_GUIDE_DESCRIPTION:
          'Get the ins and outs of property investment in Australia from our handy real estate guide',
        BUYING_LIVING_INVESTMENT_PROPERTIES_DESCRIPTION:
          'Get the ins and outs of property investment in Australia from our handy real estate guide',
      },
    },
    // END Home Page Translations

    // START Suburb Profile/s Page Translations
    SUBURB_PROFILE: {
      HEADER: {
        FEATURED_INVESTMENT: 'Featured investment apartment & townhouses',
        AUSTRALIA_BEST_INVESTMENT: "Australia's best investment property deals",
        PROPERTY_BUYING_OFFERS: 'Property buying offers & incentives',
        GET_LATEST_OFFERS: 'Get the latest offers straight to your inbox',
      },
      PLACEHOLDER: {
        SEARCH: 'Search by suburb or postcode',
      },
      BUTTON_TEXT: {
        SHOW_ALL_PROFILES: 'Show all {{ state }} Profiles',
        MORE_FEATURED_PROPERTIES: 'See more featured properties',
        MORE_BEDROOM_PROPERTIES: 'See more {{ bedroom }} bedroom properties',
        SEE_ALL_ARTICLES: 'See all articles',
      },
      TEXT: {
        PROPERTY_BUYING_DESCRIPTION:
          'Property investment grants, discounts and clearance deals in highly prized suburbs in Sydney, Melbourne, Brisbane and more!',
        OFF_THE_PLAN_BUYING_GUIDE_DESCRIPTION:
          'Get the ins and outs of property investment in Australia from our handy real estate guide',
        BUYING_LIVING_INVESTMENT_PROPERTIES_DESCRIPTION:
          'Get the ins and outs of property investment in Australia from our handy real estate guide',
      },
    },
    // END Suburb Profile Page Translations

    // START Industry Profile Page Translations
    INDUSTRY_PROFILE: {
      HEADER: {
        COMPLETED_PROJECTS: 'Completed Projects',
        RELATED_SUBURB_PROFILES: 'Related Suburb Profiles',
        ALL_PROFILES: 'All Profiles',
      },
      PLACEHOLDER: {
        SEARCH: 'Search by keyword',
      },
      BUTTON_TEXT: {
        VIEW_ALL: 'View all',
        VIEW_ALL_COMPLETED: 'View all completed',
        VIEW_ALL_PROFILES: 'View all Profiles',
        SHOW_MORE: 'Show more',
      },
    },
    // END Industry Profile Page Translations

    // START Buying & Living Page Translations
    BUYING_LIVING: {
      HEADER: {
        ALL: 'All',
        LATEST: 'The latest property news',
        MARKET_INSIGHTS: 'Market Insights',
        INDUSTRY_LEADERS: 'Industry Leaders',
        LIFESTYLE: 'Lifestyle',
        MAIN: 'Off-the-plan property news',
        SECONDARY:
          'Read the latest new property news & real estate market insights from around Australia',
      },
      PLACEHOLDER: {
        SEARCH: 'Search by keyword',
      },
    },
    // END Buying & Living Page Translations

    // START Buyer Offers & Incentives Page Translations
    BUYER_OFFERS: {
      HEADER: {
        MAIN: 'Buyer offers & incentives',
      },
      BUTTON_TEXT: {
        NOTIFY: 'Notify Me',
        LEARN_MORE: 'Learn More',
        ENQUIRE_NOW: 'Enquire Now',
      },
      TEXT: {
        SHOWING_INCENTIVES: 'Showing {{ number }} of {{ total }} Incentives',
        GET_INCENTIVES: 'Get world leading incentives',
      },
    },
    // END Buyer Offers & Incentives Page Translations

    // START Buyers Guide Page Translations
    BUYERS_GUIDE: {
      HEADER: {
        MAIN: 'Off-The-Plan Buyers Guide',
        INTRODUCTION: 'Introduction',
        FIRST_TIME_BUYERS: 'Guide for First Time Buyers',
        DOWNSIZERS: 'Guide for Downsizers',
        INVESTORS: 'Investors Guide',
        CONTINUE_READING: 'Continue Reading',
      },
      TITLE: {
        WHAT_DOES_IT_MEAN:
          "What does it mean to buy a property 'off-the-plan'?",
        HOW_DOES_BUYING:
          'How does buying off-the-plan differ from other ways of purchasing property?',
        WHY_DO_DEVELOPERS: "Why do developers sell 'off-the-plan'?",
        KEY_TERMS_DEFINED: 'Key terms defined',
        KNOW_YOUR_BUDGET: 'Know your budget',
        GOVERNMENT_INCENTIVES: 'Government Incentives',
        KEY_TERMS_FIRST_HOME_BUYER: 'Key terms for First Home Buyers',
        HOW_TO_DETERMINE:
          "How to determine if an 'off-the-plan' home is right for you when downsizing?",
        HOW_TO_NEGOTIATE:
          'How to negotiate space when buying a smaller home off-the-plan?',
        HOME_OFF_THE_PLAN: 'Home off-the-plan?',
        IS_IT_TIME: 'Is it time to downsize?',
        SHOULD_YOU_PURCHASE: 'Should you purchase an investment property?',
        KEY_BENEFITS: 'Key benefits of off-the-plan for investors',
        RELEVANT_OFF_THE_PLAN: 'Relevant off-the-plan legislation',
        AD_SPECIAL_INSIGHTS: 'AD SPECIAL INSIGHTS',
        BEST_PLACES_TO_BUY: 'Best places to buy / Most Popular suburbs',
      },
      PLACEHOLDER: {
        FIRST_NAME: 'Your first name',
        LAST_NAME: 'Your last name',
        EMAIL: 'Your email',
        STATE: 'Your state',
      },
    },
    // END Buyers Guide Page Translations

    // START Showcase Translations
    SHOWCASE: {
      DISPLAY_SUITE: {
        APPOINTMENT_ONLY: 'By appointment only',
      },
    },
    // END Showcase Translations

    // START Footer Translations
    FOOTER: {
      SIGN_UP: {
        TITLE: 'Sign up for new development alerts.',
        SUBTITLE: 'Receive every new listing in your inbox',
      },
      INVESTMENT_PROPERTIES: 'investment properties',
    },
    // END Footer Translations

    // START Sitemap Page Translations
    SITEMAP: {
      HEADER: {
        MAIN: 'Apartments & Developments Sitemap',
      },
      BODY: {
        APARTMENT: 'Off the plan apartments for sale',
        TOWNHOUSE: 'Off the plan townhouses for sale',
        LAND_ESTATE: 'Off the plan land estates for sale',
        APARTMENT_ONE_BED: '1 Bedroom off the plan apartments for sale',
        APARTMENT_TWO_BED: '2 Bedroom off the planapartments for sale',
        APARTMENT_THREE_BED: '3 Bedroom off the plan apartments for sale',
        APARTMENT_FOUR_BED: '4 Bedroom off the plan apartments for sale',
        INDUSTRY_PROFILE: 'Off the plan property industry profiles',
        GENERAL_INFO: 'General Information',
        ARTICLES: 'Property Development News',
        INDUSTRY_PROPERTIES: 'New developments & investment properties',
      },
    },
    // END Buyer Offers & Incentives Page Translations
  },
};
