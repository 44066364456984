import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';

import { environment } from 'environments/environment';
import { DeviceDetectorService } from 'ngx-device-detector';

import { AlertService, StateService } from '@core/helpers';
import {
  DevelopmentService,
  LanguageService,
  SubscriptionService,
  SuburbService,
} from '@core/services';
import {
  Address,
  ApiResponse,
  Development as DevelopmentModel,
  DevelopmentQuery,
  DevelopmentSearchQuery,
  SearchFilter,
  Subscriber,
  SuburbQuery,
} from '@core/types';

import { AppState } from '@state/app.state';
import { selectSearchFilter } from '@state/search-filter/search-filter.selectors';

interface SeoDetails {
  projectCount: number;
  propertyCount: number;
  apartmentFromPrice: string | null;
  townhousesFromPrice: string | null;
}

interface FooterSuburbs extends Address {
  totalProjects?: number;
  title?: string;
  postalNumber?: number;
  state?: string;
  projectCount?: number;
  propertyCount?: number;
  apartmentFromPrice?: string;
  townhousesFromPrice?: string;
}
type AustralianState = 'nsw' | 'vic' | 'qld' | 'sa' | 'act' | 'wa';
@Component({
  selector: 'apd-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnChanges, OnDestroy {
  @Input() state = 'nsw';
  @Input() mode: 'simple';
  @Input() providedDevelopments: Array<DevelopmentModel>;
  @Input() reloadDevelopments = true;
  @Input() fromSearchPage = false;
  @Output() filterRequest = new EventEmitter<Event>();

  suburbs: Array<FooterSuburbs> = [];

  currentLang = '';

  cdnBasePath = environment.cdnBasePath;

  isLoading = false;

  bedrooms = '1';
  classifications =
    'apartments,townhouses,new-land-estates,penthouses,prestige-homes,villas,terraces';

  subscriber: Subscriber = {
    email: '',
    tags: ['Newsletter Sign-up', 'New Site'],
  };

  developmentSearchParams: DevelopmentSearchQuery = {
    featured: true,
    address: true,
    status: 'published',
    limit: 40,
  };

  developmentParams: DevelopmentQuery = {
    address: true,
    slugs: true,
    properties: true,
    status: 'published',
    limit: 40,
  };

  suburbParams: SuburbQuery = {
    status: 'published',
    limit: 40,
  };

  loadCounter = 0;
  showSeoTextDiv = false;
  stateName: string;
  isMobile = false;
  suburbSlug: string;
  suburbPostCode: number;
  suburbName: string;
  searchedSuburb: FooterSuburbs;
  dateNow: Date;
  /**
   * Constructor
   *
   * @param {Router} router
   * @param {SubscriptionService} subscriptionService
   * @param {StateService} stateService
   * @param {DevelopmentService} searchService
   */
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private languageService: LanguageService,
    private alertService: AlertService,
    private subscriptionService: SubscriptionService,
    private developmentService: DevelopmentService,
    private suburbService: SuburbService,
    private store: Store<AppState>,
    private deviceDetectorService: DeviceDetectorService,
    public stateService: StateService
  ) {}

  ngOnInit(): void {
    this.isMobile = this.deviceDetectorService.isMobile();

    this.dateNow = new Date();

    if (!this.providedDevelopments) {
      this.handleStateInput();
      this.subscribeStore();

      if (!this.fromSearchPage) {
        this.getDevelopments();
      }
    } else {
      this.setSuburbs(this.providedDevelopments);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (typeof changes.state != 'undefined') {
      if (
        changes.state.currentValue != changes.state.previousValue ||
        !changes.state.currentValue
      ) {
        this.handleStateInput();
        this.getDevelopments();
      }
    }
  }

  ngOnDestroy(): void {
    this.suburbs = [];
  }

  subscribeStore(): void {
    this.store
      .pipe(select(selectSearchFilter))
      .subscribe((res: SearchFilter) => {
        this.setDevSearchFilters(res);
      });
  }

  setDevSearchFilters(filter: SearchFilter): void {
    const bedroomFilters = filter.bedrooms;
    let bedrooms = '';
    const bedroomMap = [
      'oneBed',
      'twoBeds',
      'threeBeds',
      'fourBeds',
      'fivePlusBeds',
    ];

    for (let i = 0; i < bedroomMap.length; i++) {
      if (bedroomFilters[bedroomMap[i]]) {
        const bedroomCount = i + 1;

        bedrooms = bedrooms + bedroomCount + ',';
      }
    }

    let classifications = '';
    for (const [key, value] of Object.entries(filter.propertyType)) {
      if (key == 'all' && value) {
        classifications =
          'apartments,townhouses,new-land-estates,penthouses,prestige-homes,villas,terraces';
        break;
      } else {
        if (value) {
          if (key == 'newLandEstates') {
            classifications = classifications + 'new-land-estates' + ',';
          } else {
            classifications = classifications + key + ',';
          }
        }
      }
    }

    this.activatedRoute.paramMap.subscribe((paramMap: ParamMap) => {
      let shortenState = paramMap.get('shortenState');
      const suburbSlug = paramMap.get('suburbSlug');

      if (typeof shortenState == 'undefined' || shortenState == null)
        shortenState = paramMap.get('state');
      if (typeof shortenState == 'undefined' || shortenState == null)
        shortenState = 'nsw';

      this.state = shortenState;
      this.stateName = this.stateService.getName(shortenState);

      if (suburbSlug !== null) {
        let suburbSlugArr = suburbSlug.split('-');

        this.suburbSlug = suburbSlug;
        this.suburbPostCode = parseInt(suburbSlugArr.pop());

        suburbSlugArr = suburbSlugArr.map(function (val) {
          const valString = val.toLowerCase(),
            valFirst = valString.slice(0, 1).toUpperCase(),
            newVal = valFirst + valString.slice(1);
          return newVal;
        });

        this.suburbName = suburbSlugArr.join(' ');

        if (this.fromSearchPage) {
          this.getSuburbs();
          this.getSearchedSuburb();
        }
      } else {
        if (this.fromSearchPage) {
          this.getSuburbs();
        }
      }
    });

    bedrooms = bedrooms.replace(/,\s*$/, '');
    classifications = classifications.replace(/,\s*$/, '');

    this.bedrooms = bedrooms;
    if (typeof filter.propertyType != 'undefined')
      this.classifications = classifications;
  }

  handleStateInput(): void {
    if (!this.state) {
      this.state = 'nsw';
    }
  }

  setState(state: AustralianState): void {
    this.state = state;
    this.showSeoTextDiv = false;

    if (!this.fromSearchPage) {
      this.getDevelopments(true);
    } else {
      this.getSuburbs();
    }
  }

  setBedroom(bedroom: string): void {
    this.classifications =
      'apartments,townhouses,new-land-estates,penthouses,prestige-homes,villas,terraces';
    this.bedrooms = bedroom;
    this.getDevelopments(true);
  }

  setClassification(
    classification:
      | 'prestige-homes'
      | 'penthouses'
      | 'villas'
      | 'terraces'
      | 'new-land-estates'
      | 'townhouses'
      | 'apartments,new-land-estates,townhouses,penthouses,prestige-homes,villas,terraces'
  ): void {
    this.classifications = classification;
    this.bedrooms = '';
    this.getDevelopments(true);
  }

  getDevelopments(triggeredByClick = false): void {
    this.loadCounter++;

    if (!triggeredByClick && !this.reloadDevelopments && this.loadCounter > 1) {
      this.reloadDevelopments = true;
      return;
    } else {
      if (triggeredByClick && this.reloadDevelopments) {
        this.suburbs = [];
      }
    }

    this.isLoading = true;

    this.developmentService
      .developmentSearch({
        bedrooms: this.bedrooms,
        classifications: this.classifications,
        state: this.stateService.getSlug(this.state),
        ...this.developmentSearchParams,
      })
      .subscribe((res: ApiResponse) => {
        const {
          data: { developments },
        } = res;
        this.setSuburbs(developments);
      });
  }

  getSearchedSuburb(): void {
    this.developmentService
      .getDevelopments({
        state: this.stateService.getSlug(this.state),
        suburb: this.suburbSlug,
        ...this.developmentParams,
      })
      .subscribe((res: ApiResponse) => {
        const {
          data: { developments },
        } = res;

        this.setSearchedSuburb(developments);
      });
  }

  setSearchedSuburb(developments: Array<DevelopmentModel>): boolean {
    const seoDetails = this.getSeoDetails(developments);

    if (typeof seoDetails === 'number') {
      return false;
    }

    const {
      projectCount,
      propertyCount,
      apartmentFromPrice,
      townhousesFromPrice,
    } = seoDetails;

    this.searchedSuburb = {
      title: this.suburbName,
      postalNumber: this.suburbPostCode,
      state: this.stateName,
      projectCount: projectCount,
      propertyCount: propertyCount,
      apartmentFromPrice: apartmentFromPrice,
      townhousesFromPrice: townhousesFromPrice,
    };

    return true;
  }

  getSeoDetails(developments: Array<DevelopmentModel>): SeoDetails | number {
    const apartmentPricesArr = [],
      townhousesPricesArr = [];
    let projectCount = 0,
      propertyCount = 0;

    developments.forEach(function (development) {
      const pricesArr = development.properties.map(property => {
        const priceString = property.price.split(',').join(''),
          price = parseInt(priceString);

        return price;
      });

      projectCount++;
      propertyCount += development.properties.length;

      if (pricesArr.length > 0) {
        pricesArr.sort((a, b) => a - b);

        if (development.classification == 'Apartments') {
          if (pricesArr[0]) apartmentPricesArr.push(pricesArr[0]);
        } else if (development.classification == 'Townhouses') {
          if (pricesArr[0]) townhousesPricesArr.push(pricesArr[0]);
        }
      }
    });

    apartmentPricesArr.sort((a, b) => a - b);
    townhousesPricesArr.sort((a, b) => a - b);

    return {
      projectCount: projectCount,
      propertyCount: propertyCount,
      apartmentFromPrice:
        apartmentPricesArr.length > 0
          ? apartmentPricesArr[0].toLocaleString()
          : null,
      townhousesFromPrice:
        townhousesPricesArr.length > 0
          ? townhousesPricesArr[0].toLocaleString()
          : null,
    };
  }

  getSuburbs(): void {
    this.loadCounter++;

    this.isLoading = true;

    this.suburbService
      .getSuburbs({
        state: this.stateService.getSlug(this.state),
        ...this.suburbParams,
      })
      .subscribe((res: ApiResponse) => {
        const {
          data: { suburbs },
        } = res;

        this.suburbs = !this.isMobile
          ? suburbs.slice(0, 30)
          : suburbs.slice(0, 10);

        this.suburbs.map(suburb => (suburb['area'] = suburb.title));

        this.isLoading = false;
      });
  }

  setSuburbs(developments: Array<DevelopmentModel>): void {
    developments.forEach(({ address }) => {
      if (this.checkIfSuburbExists(address.slug)) {
        if (this.state == address.shortenState.toLowerCase()) {
          this.suburbs.push({
            ...address,
            totalProjects: 1,
          });
        }
      } else {
        const index = this.suburbs.findIndex(suburb => {
          return suburb.slug == address.slug;
        });

        this.suburbs[index].totalProjects++;
      }
    });

    this.suburbs.sort((a, b) => b.totalProjects - a.totalProjects);

    this.isLoading = false;
  }

  checkIfSuburbExists(slug: string): boolean {
    const found = this.suburbs.filter(res => res.slug === slug);
    return found.length === 0;
  }

  subscribe(event: Event): void {
    event.preventDefault();
    this.subscriptionService.subscribeUser(this.subscriber).subscribe(
      () => {
        this.alertService.generic('You have been subscribed.', 'success');
      },
      error => {
        if (error.error_code === 422) {
          this.alertService.generic('Email address already subscribed');
        } else {
          this.alertService.generic(
            'Error while subscribing. Please try again later'
          );
        }
      }
    );
  }

  showSeoText(show: boolean): void {
    this.showSeoTextDiv = show;
  }
}
