import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiResponse, ContentQuery } from '@core/types';

import { ApiService } from '../api.service';

@Injectable()
export class ContentService {
  /**
   * Constructor
   *
   * @param {ApiService} apiService
   */
  constructor(private apiService: ApiService) {}

  /**
   * Get content banners
   *
   * @returns {Observable<ApiResponse>}
   */
  getBanners(params: ContentQuery): Observable<ApiResponse> {
    console.info('GET BANNERS');
    console.info(params);
    const httpParams = this.apiService.setHttpParams(params);
    return this.apiService
      .get('/content/banners', httpParams)
      .pipe(map(response => response));
  }
}
