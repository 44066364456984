<div
  [hidden]="!showFilters"
  id="search-modal-overlay"
  (click)="closePopup($event)"
  class="search-filters align-items-center position-fixed w-100 h-100">
  <div class="box mx-auto w-100 h-100">
    <div class="scrolling-wrapper w-100 h-100">
      <div
        class="head text-center d-grid align-items-center w-100"
        *ngIf="showSpecificFilter()">
        <div class="wrapper">
          <a (click)="togglePopup()" id="close" class="position-absolute">
            <img
              loading="lazy"
              [src]="cdnBasePath + '/assets/icon/close.svg' | optimizeImage"
              alt="Close" />
          </a>
          <h3>{{ '_FORM.FILTERS' | translate }}</h3>
        </div>
      </div>
      <div class="padding-wrapper">
        <form [formGroup]="filterForm" novalidate>
          <div
            formGroupName="propertyType"
            class="filter property-type"
            *ngIf="showSpecificFilter('property')">
            <div class="wrapper">
              <h4 class="h5 title">
                {{ '_PROPERTY.TYPE' | translate }}
              </h4>
              <a
                (click)="togglePopup()"
                id="close"
                class=""
                *ngIf="!showSpecificFilter()">
                <img
                  loading="lazy"
                  [src]="cdnBasePath + '/assets/icon/close.svg' | optimizeImage"
                  alt="Close" />
              </a>
            </div>
            <label>
              <input
                formControlName="all"
                type="checkbox"
                name="property-type" />
              <span class="option-label">All</span>
            </label>
            <label>
              <input
                formControlName="apartments"
                type="checkbox"
                name="property-type" />
              <span class="option-label">{{
                '_DEVELOPMENT.APARTMENT.PLURAL' | translate
              }}</span>
            </label>
            <label>
              <input
                formControlName="townhouses"
                type="checkbox"
                name="property-type" />
              <span class="option-label">{{
                '_DEVELOPMENT.TOWNHOUSE.PLURAL' | translate
              }}</span>
            </label>
            <label>
              <input
                formControlName="newLandEstates"
                type="checkbox"
                name="property-type" />
              <span class="option-label">{{
                '_DEVELOPMENT.LAND_ESTATE.PLURAL' | translate
              }}</span>
            </label>
            <label>
              <input
                formControlName="penthouses"
                type="checkbox"
                name="property-type" />
              <span class="option-label">{{
                '_DEVELOPMENT.PENTHOUSE.PLURAL' | translate
              }}</span>
            </label>
            <label>
              <input
                formControlName="prestigeHomes"
                type="checkbox"
                name="property-type" />
              <span class="option-label">{{
                '_DEVELOPMENT.PRESTIGE_HOME.PLURAL' | translate
              }}</span>
            </label>
            <label>
              <input
                formControlName="villas"
                type="checkbox"
                name="property-type" />
              <span class="option-label">{{
                '_DEVELOPMENT.VILLA.PLURAL' | translate
              }}</span>
            </label>
            <label>
              <input
                formControlName="terraces"
                type="checkbox"
                name="property-type" />
              <span class="option-label">{{
                '_DEVELOPMENT.TERRACE.PLURAL' | translate
              }}</span>
            </label>
          </div>

          <div
            formGroupName="bedrooms"
            class="filter bedrooms"
            *ngIf="showSpecificFilter('bedrooms')">
            <div class="wrapper">
              <h4 class="h5 title">
                {{ '_PROPERTY.BEDROOM.PLURAL' | translate }}
              </h4>
              <a
                (click)="togglePopup()"
                id="close"
                class=""
                *ngIf="!showSpecificFilter()">
                <img
                  loading="lazy"
                  [src]="cdnBasePath + '/assets/icon/close.svg' | optimizeImage"
                  alt="Close" />
              </a>
            </div>
            <label>
              <input formControlName="oneBed" type="checkbox" name="bedrooms" />
              <span class="option-label">1</span>
            </label>
            <label>
              <input
                formControlName="twoBeds"
                type="checkbox"
                name="bedrooms" />
              <span class="option-label">2</span>
            </label>
            <label>
              <input
                formControlName="threeBeds"
                type="checkbox"
                name="bedrooms" />
              <span class="option-label">3</span>
            </label>
            <label>
              <input
                formControlName="fourBeds"
                type="checkbox"
                name="bedrooms" />
              <span class="option-label">4</span>
            </label>
            <label>
              <input
                formControlName="fivePlusBeds"
                type="checkbox"
                name="bedrooms" />
              <span class="option-label">5+</span>
            </label>
          </div>

          <div
            formGroupName="bathrooms"
            class="filter bathrooms"
            *ngIf="showSpecificFilter('bathrooms')">
            <div class="wrapper">
              <h4 class="h5 title">
                {{ '_PROPERTY.BATHROOMS' | translate }}
              </h4>
              <a
                (click)="togglePopup()"
                id="close"
                class=""
                *ngIf="!showSpecificFilter()">
                <img
                  loading="lazy"
                  [src]="cdnBasePath + '/assets/icon/close.svg' | optimizeImage"
                  alt="Close" />
              </a>
            </div>
            <label>
              <input
                formControlName="oneBath"
                type="checkbox"
                name="bathrooms" />
              <span class="option-label">1</span>
            </label>
            <label>
              <input
                formControlName="twoBath"
                type="checkbox"
                name="bathrooms" />
              <span class="option-label">2</span>
            </label>
            <label>
              <input
                formControlName="threeBath"
                type="checkbox"
                name="bathrooms" />
              <span class="option-label">3</span>
            </label>
            <label>
              <input
                formControlName="fourBath"
                type="checkbox"
                name="bathrooms" />
              <span class="option-label">4</span>
            </label>
            <label>
              <input
                formControlName="fivePlusBath"
                type="checkbox"
                name="bathrooms" />
              <span class="option-label">5+</span>
            </label>
          </div>

          <div
            formGroupName="carSpaces"
            class="filter car-spaces"
            *ngIf="showSpecificFilter('carspaces')">
            <div class="wrapper">
              <h4 class="h5 title">
                {{ '_PROPERTY.CAR_SPACES' | translate }}
              </h4>
              <a
                (click)="togglePopup()"
                id="close"
                class=""
                *ngIf="!showSpecificFilter()">
                <img
                  loading="lazy"
                  [src]="cdnBasePath + '/assets/icon/close.svg' | optimizeImage"
                  alt="Close" />
              </a>
            </div>
            <label>
              <input
                formControlName="oneSpace"
                type="checkbox"
                name="car-spaces" />
              <span class="option-label">1</span>
            </label>
            <label>
              <input
                formControlName="twoSpace"
                type="checkbox"
                name="car-spaces" />
              <span class="option-label">2</span>
            </label>
            <label>
              <input
                formControlName="threeSpace"
                type="checkbox"
                name="car-spaces" />
              <span class="option-label">3</span>
            </label>
            <label>
              <input
                formControlName="fourSpace"
                type="checkbox"
                name="car-spaces" />
              <span class="option-label">4</span>
            </label>
            <label>
              <input
                formControlName="fivePlusSpace"
                type="checkbox"
                name="car-spaces" />
              <span class="option-label">5+</span>
            </label>
          </div>

          <div
            formGroupName="price"
            class="filter price-range"
            *ngIf="showSpecificFilter('price')">
            <div class="wrapper">
              <h4 class="h5 title">
                {{ '_PROPERTY.PRICE' | translate }}
              </h4>
              <a
                (click)="togglePopup()"
                id="close"
                class=""
                *ngIf="!showSpecificFilter()">
                <img
                  loading="lazy"
                  [src]="cdnBasePath + '/assets/icon/close.svg' | optimizeImage"
                  alt="Close" />
              </a>
            </div>
            <div class="d-grid price-range-select">
              <label>
                <span class="option-label">{{
                  '_PRICES.MIN' | translate
                }}</span>
                <select formControlName="min" name="min">
                  <option [ngValue]="'Any'" selected>
                    {{ '_FORM.PLACEHOLDER.ANY' | translate }}
                  </option>
                  <option
                    [ngValue]="price.value"
                    *ngFor="let price of priceRanges">
                    {{ price.option }}
                  </option>
                </select>
                <span
                  class="option-label error-message"
                  *ngIf="
                    filterForm.controls.price.get('min').hasError('greaterThan')
                  ">
                  Should be less than max price
                </span>
              </label>
              <div
                class="price-range-separator"
                *ngIf="specificTypeFilterExists()">
                <hr noshade />
              </div>
              <label>
                <span class="option-label">{{
                  '_PRICES.MAX' | translate
                }}</span>
                <select formControlName="max" name="max">
                  <option [ngValue]="'Any'" selected>
                    {{ '_FORM.PLACEHOLDER.ANY' | translate }}
                  </option>
                  <option
                    [ngValue]="price.value"
                    *ngFor="let price of priceRanges">
                    {{ price.option }}
                  </option>
                </select>
                <span
                  class="option-label error-message"
                  *ngIf="
                    filterForm.controls.price.get('max').hasError('lessThan')
                  ">
                  Should be greater than min price
                </span>
              </label>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="actions d-grid align-items-center">
      <div class="clear">
        <a *ngIf="specificTypeFilterExists()" (click)="onReset()">{{
          '_FORM.CLEAR' | translate
        }}</a>
        <a *ngIf="!specificTypeFilterExists()" (click)="onReset()">{{
          '_FORM.CLEAR_FILTERS' | translate
        }}</a>
      </div>
      <div class="show">
        <button
          (click)="search()"
          [disabled]="filterForm.invalid"
          class="btn btn--three"
          *ngIf="specificTypeFilterExists()">
          {{ '_FORM.APPLY' | translate }}
        </button>
        <button
          (click)="search()"
          [disabled]="filterForm.invalid"
          class="btn btn--three"
          *ngIf="!specificTypeFilterExists()">
          {{ '_FORM.APPLY_FILTERS' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
