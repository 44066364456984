import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { environment } from 'environments/environment';

import { SlideAnimation } from '@core/animations/animation';
import { StateService } from '@core/helpers';
import { SearchBarComponent } from '@core/layout/components/search-bar/search-bar.component';
import { LanguageService, LocalStorageService } from '@core/services';
import { SearchFilter } from '@core/types';
import { Menu } from '@core/types/common/menu.model';

import { Constants } from '@common/constants';

@Component({
  selector: 'apd-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [SlideAnimation],
})
export class HeaderComponent implements OnInit, OnChanges {
  @ViewChild(SearchBarComponent) searchBarNode: SearchBarComponent;

  @Input() theme: string;
  @Input() mode: 'simple';
  @Input() no: string;
  @Input() brandLogo: string;
  @Input() brandUrl: string;
  @Input() trackKeyWord = false;
  @Input() showMobileMenu = false;
  @Input() showSearchBar = true;
  @Input() showStateToggle = false;
  @Input() isSearchPage = false;

  @Input() activeStateFilter: string;
  @Input() mobileHidden: boolean;

  @Output() hidePopupEventEmitter = new EventEmitter<boolean>();
  @Output() toggleMobileFilter = new EventEmitter<boolean>();

  @HostListener('window:click') onClickOutside(): void {
    this.disableDropdowns();
  }

  mobileNavtoggles = {
    searchApartments: false,
    searchTownhouses: false,
    buyingLiving: false,
    developments: false,
    news: false,
    industryProfiles: false,
  };

  cdnBasePath = environment.cdnBasePath;

  dropdowns: { [key: string]: boolean } = {
    development: false,
    news: false,
    profile: false,
  };
  currentLang: string;
  activeState = 'Developments';
  animationState = 'up';
  shortStateForm: string;
  menu: Array<Menu>;
  newsMenu = 'Property News';
  industryProfilesMenu = 'Industry Profiles';

  defaultFilters: SearchFilter = {
    propertyType: {
      all: false,
      apartments: false,
      townhouses: false,
      newLandEstates: false,
      penthouses: false,
      prestigeHomes: false,
      villas: false,
      terraces: false,
    },
    bedrooms: {
      oneBed: false,
      twoBeds: false,
      threeBeds: false,
      fourBeds: false,
      fivePlusBeds: false,
    },
    bathrooms: {
      oneBath: false,
      twoBath: false,
      threeBath: false,
      fourBath: false,
      fivePlusBath: false,
    },
    carSpaces: {
      oneSpace: false,
      twoSpace: false,
      threeSpace: false,
      fourSpace: false,
      fivePlusSpace: false,
    },
    price: {
      min: 'Any',
      max: 'Any',
    },
  };

  /**
   *
   * @param router Router
   * @param languageService LanguageService
   */
  constructor(
    private router: Router,
    private languageService: LanguageService,
    private localStorageService: LocalStorageService,
    private stateService: StateService
  ) {}

  /**
   * On init
   * @return void
   */
  ngOnInit(): void {
    this.menu = Constants.mainMenuOptions;
    this.currentLang = this.languageService.getCurrentLang();

    /**
     * Redirect to new page and show top contents
     */
    this.router.events.subscribe(event => {
      if (!(event instanceof NavigationEnd)) {
        return;
      }
      this.showMobileMenu = false;
      window.scrollTo(0, 0);
    });
  }

  ngOnChanges(): void {
    if (this.activeStateFilter !== 'Australia') {
      this.activeState = this.activeStateFilter;
    }
  }

  toggleSearchFilterPopup(event: Event = null): void {
    return this.searchBarNode.toggleSearchFilterPopup(event);
  }

  /**
   * Toggle show mobile menu
   * @return void
   */
  toggleMobileMenu(): void {
    this.showMobileMenu = !this.showMobileMenu;
  }

  applySearch(): void {
    console.log(this.localStorageService.getSearchFilters());
  }

  toggleStateDropdown(event: Event, key: string): void {
    event.stopPropagation();
    this.disableDropdowns(key);
    this.dropdowns[key] = !this.dropdowns[key];
  }

  toggleMobileDropdown(item: string): void {
    const mobileNavtoggleArray = Object.keys(this.mobileNavtoggles);
    mobileNavtoggleArray.forEach(value => {
      if (item == value) {
        this.mobileNavtoggles[value] = !this.mobileNavtoggles[value];
      } else {
        this.mobileNavtoggles[value] = false;
      }
    });
  }

  setActiveState(state: string): void {
    this.activeState = state;
    this.animationState = 'up';
    this.shortStateForm = this.stateService.getShortValue(state);

    this.shortStateForm === null
      ? this.router.navigate([this.currentLang + '/'])
      : this.router.navigate([
          this.currentLang +
            '/new-developments-investment-property/' +
            this.shortStateForm,
        ]);
  }

  redirectTo(type: string, slug: string): void {
    this.defaultFilters.propertyType[type] = true;
    this.localStorageService.setSearchFilters(this.defaultFilters);
    this.router.navigate([`/new-apartments-developments/${slug}`]);
  }

  disableDropdowns(currentIndex?: string): void {
    for (const key in this.dropdowns) {
      const value = this.dropdowns[key];
      this.dropdowns[key] = currentIndex !== key ? false : value;
    }
  }

  openBrokerFinderLink(): void {
    window.open('https://brokerfinder.a-d.com.au/', '_blank').focus();
  }
}
