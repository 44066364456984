import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';

import { MapToggleService } from '@core/helpers/map-toggle.service';

@Component({
  selector: 'apd-map-toggle',
  templateUrl: './map-toggle.component.html',
  styleUrls: ['./map-toggle.component.scss'],
})
export class MapToggleComponent implements OnInit {
  @Input() isMobileView: boolean;
  isMapShown = false;
  currentScroll = 0;

  constructor(
    private mapToggleService: MapToggleService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit(): void {
    this.mapToggleService.isMapShown$.subscribe(
      isShown => (this.isMapShown = isShown)
    );
  }

  onToggleMap(): void {
    this.isMapShown = !this.isMapShown;
    this.mapToggleService.setIsMapShown(this.isMapShown);

    this.scrollPage();
  }

  scrollPage(): void {
    if (!this.isMapShown) {
      this.currentScroll = this.document.documentElement.scrollTop;
      this.document.documentElement.setAttribute(
        'style',
        'scroll-behavior: auto'
      );
      this.document.documentElement.scrollTo({ top: 0, behavior: 'auto' });
    } else {
      this.document.documentElement.setAttribute(
        'style',
        'scroll-behavior: auto'
      );
      this.document.documentElement.scrollTo({
        top: this.currentScroll,
        behavior: 'auto',
      });
    }
  }
}
