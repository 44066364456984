export const locale = {
  lang: 'cn',
  data: {
    // START Global Translations
    _BUYER_INCENTIVES: '买家奖励',
    _BUYING_LIVING: '购买与生活',
    _BUYERS_GUIDE: '采购指南',
    _HOUSE_LAND_PACKAGES: '房屋及土地配套',
    _INDUSTRY_PROFILES: '行业概况',
    _SUBURB_PROFILES: '郊区资料',
    _DEVELOPMENT: {
      APARTMENT: {
        SINGULAR: '公寓',
        PLURAL: '公寓式',
      },
      TOWNHOUSE: {
        SINGULAR: '联排别墅',
        PLURAL: '联排别墅',
      },
      LAND_ESTATE: {
        SINGULAR: '土地地产',
        PLURAL: '土地遗产',
      },
      PENTHOUSE: {
        SINGULAR: '阁楼',
        PLURAL: '顶层公寓',
      },
      PRESTIGE_HOME: {
        SINGULAR: '尊贵之家',
        PLURAL: '威望家园',
      },
      VILLA: {
        SINGULAR: '别墅',
        PLURAL: '别墅',
      },
      TERRACE: {
        SINGULAR: '阳台',
        PLURAL: '露台',
      },
    },
    _PROPERTY: {
      TYPE: '财产种类',
      BEDROOM: {
        SINGULAR: '卧室',
        PLURAL: '卧室',
      },
      BATHROOMS: '洗手间',
      CARS: '汽车',
      CAR_SPACES: '车位',
      PRICE: '价格',
      DEVELOPER: '开发者',
    },
    _SUPPLIER: {
      AGENT: {
        SINGULAR: '代理人',
        PLURAL: '代理商',
      },
      ARCHITECT: {
        SINGULAR: '建筑师',
        PLURAL: '建筑师',
      },
      BUILDER: {
        SINGULAR: '建造者',
        PLURAL: '建筑商',
      },
      DEVELOPER: {
        SINGULAR: '开发者',
        PLURAL: '开发者',
      },
      PROJECT_MARKETER: {
        SINGULAR: '项目营销人员',
        PLURAL: '项目营销人员',
      },
      INTERIOR_DESIGNER: {
        SINGULAR: 'Interior Designer',
        PLURAL: 'Interior Designers',
      },
      LANDSCAPE_ARCHITECT: {
        SINGULAR: 'Landscape Architect',
        PLURAL: 'Landscape Architects',
      },
      DEVELOPER_BUILDER: {
        SINGULAR: '开发者/建造者',
        PLURAL: '开发商/建筑商',
      },
      PROJCET_MANAGER: {
        SINGULAR: '专案经理',
        PLURAL: '项目经理',
      },
    },
    _DEMOGRAPHICS: {
      HOUSEHOLDS: '住户',
      YOUNGER_PROFESSIONALS: '年轻的专业人​​士',
      FIRST_HOME_BUYERS: '首次购房者',
      EARLY_CAREERS: '抢先体验',
    },
    _PRICES: {
      MIN: '敏',
      MAX: '最大限度',
      AVERAGE_HOUSE: '平均房价',
    },
    _FORM: {
      PLACEHOLDER: {
        SEARCH: '按郊区或发展进行搜索',
        NAME: '姓名',
        PHONE_NUMBER: '电话号码',
        EMAIL_ADDRESS: '电子邮件地址',
        ANY: '任何',
      },
      FILTERS: '筛选器',
      CLEAR_FILTERS: '清除筛选器',
      SHOW_FILTER_RESULTS: '显示{{ total }}结果',
      CLEAR: '清楚',
      APPLY_FILTERS: '应用筛选器',
      APPLY: '应用',
    },
    // END Global Translations

    // START Home Page Translations
    HOME: {
      HEADER: {
        FIND_INVESTMENT_PROPERTY: '寻找您的投资物业',
        INVESTMENT_PROPERTY_OPPORTUNITIES:
          '投资物业机会，购房者和投资者可从澳大利亚顶级郊区找到最好的公寓，联排别墅和待售土地.',
        FEATURED_INVESTMENT: '特色投资公寓和联排别墅',
        AUSTRALIA_BEST_INVESTMENT: '澳大利亚最佳投资物业交易',
        PROPERTY_BUYING_OFFERS: '购房优惠和激励措施',
        GET_LATEST_OFFERS: '将最新优惠直接发送到您的收件箱',
        TOP_INVESTMENT_SUBURBS: '计划外买家的顶级投资郊区',
        OFF_THE_PLAN_BUYING_GUIDE: '您的计划外购买指南',
        BUYING_LIVING_INVESTMENT_PROPERTIES: '在澳大利亚购买和居住投资物业',
      },
      PLACEHOLDER: {
        SEARCH: '按郊区或发展进行搜索',
      },
      BUTTON_TEXT: {
        MORE_FEATURED_PROPERTIES: '查看更多特色住宿',
        MORE_BEDROOM_PROPERTIES: '查看更多{{ bedroom }}间卧室的住宿',
        SEE_ALL_TOP_SUBURBS: '查看所有热门郊区',
        VIEW_BUYERS_GUIDE: '查看买家指南',
        SEE_ALL_ARTICLES: '查看所有文章',
      },
      TEXT: {
        PROPERTY_BUYING_DESCRIPTION:
          '在悉尼，墨尔本，布里斯班等更多受人尊敬的郊区进行房地产投资赠款，折扣和清仓交易！',
        OFF_THE_PLAN_BUYING_GUIDE_DESCRIPTION:
          '从我们方便的房地产指南中了解澳大利亚房地产投资的来龙去脉',
        BUYING_LIVING_INVESTMENT_PROPERTIES_DESCRIPTION:
          '从我们方便的房地产指南中了解澳大利亚房地产投资的来龙去脉',
      },
    },
    // END Home Page Translations

    // START Suburb Profile/s Page Translations
    SUBURB_PROFILE: {
      HEADER: {
        FEATURED_INVESTMENT: '特色投资公寓和联排别墅',
        AUSTRALIA_BEST_INVESTMENT: '澳大利亚最佳投资物业交易',
        PROPERTY_BUYING_OFFERS: '购房优惠和激励措施',
        GET_LATEST_OFFERS: '将最新优惠直接发送到您的收件箱',
      },
      PLACEHOLDER: {
        SEARCH: '按郊区或邮政编码搜索',
      },
      BUTTON_TEXT: {
        SHOW_ALL_PROFILES: '显示所有 {{ state }} 个人资料',
        MORE_FEATURED_PROPERTIES: '查看更多特色住宿',
        MORE_BEDROOM_PROPERTIES: '查看更多{{ bedroom }}间卧室的住宿',
        SEE_ALL_ARTICLES: '查看所有文章',
      },
      TEXT: {
        PROPERTY_BUYING_DESCRIPTION:
          '在悉尼，墨尔本，布里斯班等更多受人尊敬的郊区进行房地产投资赠款，折扣和清仓交易！',
        OFF_THE_PLAN_BUYING_GUIDE_DESCRIPTION:
          '从我们方便的房地产指南中了解澳大利亚房地产投资的来龙去脉',
        BUYING_LIVING_INVESTMENT_PROPERTIES_DESCRIPTION:
          '从我们方便的房地产指南中了解澳大利亚房地产投资的来龙去脉',
      },
    },
    // END Suburb Profile Page Translations

    // START Industry Profile Page Translations
    INDUSTRY_PROFILE: {
      HEADER: {
        COMPLETED_PROJECTS: '已完成的项目',
        RELATED_SUBURB_PROFILES: '相关郊区资料',
        ALL_PROFILES: '所有个人资料',
      },
      PLACEHOLDER: {
        SEARCH: '按关键词搜索',
      },
      BUTTON_TEXT: {
        VIEW_ALL: '查看全部',
        VIEW_ALL_COMPLETED: '查看全部完成',
        VIEW_ALL_PROFILES: '查看所有{{ industry }}个人资料',
      },
    },
    // END Industry Profile Page Translations

    // START Buying & Living Page Translations
    BUYING_LIVING: {
      HEADER: {
        ALL: '全部',
        LATEST: '最新的',
        MARKET_INSIGHTS: '市场洞察',
        INDUSTRY_LEADERS: '行业领袖',
        LIFESTYLE: '生活方式',
        MAIN: 'Off-the-plan property news',
        SECONDARY:
          'Read the latest new property news & real estate market insights from around Australia',
      },
      PLACEHOLDER: {
        SEARCH: '按关键词搜索',
      },
    },
    // END Buying & Living Page Translations

    // START Buyer Offers & Incentives Page Translations
    BUYER_OFFERS: {
      HEADER: {
        MAIN: '买家优惠和奖励',
      },
      BUTTON_TEXT: {
        NOTIFY: '提醒我',
        LEARN_MORE: '学到更多',
        ENQUIRE_NOW: '立即咨询',
      },
      TEXT: {
        SHOWING_INCENTIVES: '显示{{ total }}项奖励中的{{ number }}项',
        GET_INCENTIVES: '获得世界领先的奖励',
      },
    },
    // END Buyer Offers & Incentives Page Translations

    // START Footer Translations
    FOOTER: {
      SIGN_UP: {
        TITLE: '注册以获取新的开发警报.',
        SUBTITLE: '接收收件箱中的所有新列表',
      },
      INVESTMENT_PROPERTIES: '投资物业',
    },
    // END Footer Translations
  },
};
